import { PixelRatio, Platform } from "react-native";
import i18n from "./Localization/Localization";

//URL des apps sur le store
const iOSAppUrl = 'https://apps.apple.com/app/id1515173016';
const androidAppUrl = 'https://play.google.com/store/apps/details?id=fr.aotb.lili';
export const appUrl = Platform.OS === 'ios' ? iOSAppUrl : androidAppUrl;

export const teacherFormFlavor = false;
export const educationFlavor = true;


// Déclaration du context
const teacher = "teacher";
const neutral = "neutral";
const family = "family";
const animator = "animator";
const doctor = "doctor";
export const CONTEXT = {
    neutral,
    teacher,
    family,
    animator,
    doctor
}
export type UserType = "neutral" | "teacher" | "family" | "animator" | "doctor";
export type UserCategory = "final_user" | "manager";


// Déclaration des écrans utilisateur
export const ScreenAbout = "/about";
export const ScreenActivityForm = "/activityForm";
export const ScreenActivitiesBySection = "/activitiesBySection";
export const ScreenActivityMediaList = "/activityMediaList";
export const ScreenActivityMediaSheet = "/activityMediaSheet";
export const ScreenActivityPager = "/activityPager";
export const ScreenActivitySheet = "/activitySheet";
export const ScreenActivitiesTags = "/activitiesTags";
export const ScreenAdminGifts = "/adminGifts";
export const ScreenAdminQuoteManagement = "/adminQuoteManagement";
export const ScreenAdminAmplitude = "/adminAmplitude";
export const ScreenAdminTNEStats = "/adminTNEStats";
export const ScreenAdminOfflineManagement = "/offlineManagement";
export const ScreenAppMenu = "/appMenu";
export const ScreenAuthentication = "/authentication";
export const ScreenAuthLanding = "/authLanding";
export const ScreenCategoryHome = "/categoryHome";
export const ScreenConsentNotification = "/consentNotification";
export const ScreenContact = "/contact";
export const ScreenCookiesPolicy = "/cookiesPolicy";
export const ScreenDeeplink = "/deeplink";
export const ScreenForgotPassword = "/forgotPassword";
export const ScreenFullScreenCategory = "/fullScreenCategory";
export const ScreenLegalMentions = "/legalMentions";
export const ScreenLogout = "/logout";
export const ScreenMood = "/mood";
export const ScreenNotifications = "/notifications";
export const ScreenSplashscreen = teacherFormFlavor === false ? "/" : "/Splashscreen";
export const ScreenSectionCategories = "/sectionCategories";
export const ScreenUserProfile = "/userProfile";
export const ScreenUserFavorites = "/userFavorites";
export const ScreenUserRewards = "/userRewards";
export const ScreenUserDownloads = "/userDownloads";
export const ScreenGiftCards = "/userGiftCards";
export const ScreenTeacherForm = teacherFormFlavor === false ? "/teacherForm" : "/";
export const ScreenSuperPowersPlaylist = "/superPowersPlaylist";
export const ScreenSubscription = "/subscription";
export const ScreenStripeCallback = "/stripeCallBack";
export const ScreenSelectedSubscription = "/selectSubscription";
export const ScreenSuperAdmin = "/superadmin";
export const ScreenSuperRoom = "/superRoom";
export const ScreenCardActivation = "/cardActivation";
export const ScreenPDFViewer = "/pdfViewer";
export const ScreenTicketForm = "/ticket";

// Déclaration des collections de la base
export const DBCollectionActions = "actions";
export const DBCollectionActivities = "db_activities";
export const DBCollectionCategories = "db_categories";
export const DBCollectionNews = "db_news";
export const DBCollectionLaunchSteps = "db_launch_steps";
export const DBCollectionGameLabels= "gameLabels";
export const DBCollectionGameImages= "db_game_imgs";
export const DBCollectionQuotes = "db_quotes";
export const DBCollectionCodes = "codes";
export const DBCollectionUsers = "users";
export const DBCollectionSchools = "db_schools";
export const DBCollectionGifts = "db_gifts";
export const DBCollectionAdvices = "userAdvices";
export const DBCollectionRCCustomers = "rc_customers";
export const DBCollectionStripeCheckout = "stripe_checkout";
export const DBCollectionStats = "stats";


// Déclaration des clés de l'AsyncStorage
export const keyGameHistory = "gameHisto";
export const keyUserData = "userData";
export const keyGdprConsent = "gdprConsent";
export const keyMood = "mood";
export const keyNotificationData = "notificationData";
export const keyAdminData = "adminData";
export const keyFiltersByLevel = "filtersByLevel";

// Déclaration des clés des évènements
export const EventSignUpChoiceKey = "eventSignUpChoiceKey";
export const EventUserAuthDB = "eventUserAuthDB";
export const EventUserDataDB = "eventUserDataDB";
export const EventMoodFromProfileSelected = "eventMoodFromProfileSelected";
export const EventGRPDConsentDone = "eventGRPDConsentDone";
export const EventNotificationChoiceDone = "eventNotificationChoiceDone";
export const EventMenuSettingsChange = "eventMenuSettingsChange";
// Évènements de Navigation pour le web
export const EventPush = "eventPush";
export const EventPop = "eventPop";
export const EventShowModal = "showModal";
export const EventDismissmodal = "dismissModal";
export const EventRelaunchApp = "relaunchApp";
export const EventFiltersByLevelChanged = "EventFiltersByLevelChanged";

// Valeurs possible des filtres
export const filtres = {
    cycle1:i18n.t("filters.cycle1"),
    cycle2:i18n.t("filters.cycle2"),
    cycle3:i18n.t("filters.cycle3")
}

//Origin possible des images
export const ImageOrigin = {
    REMOTE: 'remote',
    BUNDLE: 'bundle',
    ASSETS: 'assets',
    LOCAL: 'local'
}

// Superpowers
export const superPowersKeys = {
    FOCUS: "focus",
    EMOTIONS_TAMING: "emotionsTaming",
    ERROR_TAMING: "errorTaming",
    SPEAK: "speak",
    COLLECTIVE: "collective",
    CRITICAL_THINKING: "criticalThinking",
    SELF_ESTEEM: "selfEsteem",
    SLEEP: "sleep"
}

export const getScaleKey = () => {
    const scale = parseInt(PixelRatio.get().toFixed(0));
    let scaleKey = "4x";
    if (scale === 1) {
        scaleKey = "1x";
    } else if (scale === 1.5) {
        scaleKey = "1_5x";
    } else if (scale === 2) {
        scaleKey = "2x";
    } else if (scale === 3) {
        scaleKey = "3";
    } else if (scale === 3.5) {
        scaleKey = "3_5x";
    }
    return scaleKey;
}