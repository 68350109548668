import User from "../../../shared/data/user/User";
import { ScreenStripeCallback } from "../../../shared/utils/Constants";
import getStripe, { stripeVersionProd } from "./StripeInitialisation";

import offerings from "./offerings.json";

// Compatibilité React Native
const Puchases = undefined;
export { Puchases };

export const initInAppPurchaseSDK = async () => {

}

export type SubscriptionDiscount = {
    readonly identifier: string;
    readonly price: number;
    readonly priceString: string;
    readonly cycles: number;
    readonly period: string;
    readonly periodUnit: string;
    readonly periodNumberOfUnits: number;
}

export type SubscriptionPrice = {
    price: number,
    priceString: string,
    periodNumberOfUnits: number,
    period: string,
    cycles: number,
    periodUnit: string
}

export declare enum PRODUCT_CATEGORY {
    NON_SUBSCRIPTION = "NON_SUBSCRIPTION",
    SUBSCRIPTION = "SUBSCRIPTION",
    UNKNOWN = "UNKNOWN"
}
export declare enum PRODUCT_TYPE {
    CONSUMABLE = "CONSUMABLE",
    NON_CONSUMABLE = "NON_CONSUMABLE",
    NON_RENEWABLE_SUBSCRIPTION = "NON_RENEWABLE_SUBSCRIPTION",
    AUTO_RENEWABLE_SUBSCRIPTION = "AUTO_RENEWABLE_SUBSCRIPTION",
    PREPAID_SUBSCRIPTION = "PREPAID_SUBSCRIPTION",
    UNKNOWN = "UNKNOWN"
}

export type SubscriptionProduct = {
    title: string,
    introPrice: SubscriptionPrice | null,
    discounts: SubscriptionDiscount[] | null,
    productType: PRODUCT_TYPE,
    description: string,
    identifier: string,
    productCategory: PRODUCT_CATEGORY | null,
    price: number,
    currencyCode: string,
    priceString: string
}

export declare enum PACKAGE_TYPE {
    UNKNOWN = "UNKNOWN",
    CUSTOM = "CUSTOM",
    LIFETIME = "LIFETIME",
    ANNUAL = "ANNUAL",
    SIX_MONTH = "SIX_MONTH",
    THREE_MONTH = "THREE_MONTH",
    TWO_MONTH = "TWO_MONTH",
    MONTHLY = "MONTHLY",
    WEEKLY = "WEEKLY"
}

export const numberOfMonthsByPackageType = {
    ANNUAL: 12,
    SIX_MONTH: 6,
    THREE_MONTH: 3,
    TWO_MONTH: 2,
    MONTHLY: 1
}

export type SubscriptionPackage = {
    packageType: PACKAGE_TYPE,
    product: SubscriptionProduct,
    offeringIdentifier: string,
    identifier: string
}

export const restorePurchase = async (email: string) => {
    
}

export const getOfferings = async () => {
    try {
        const user: User = User.getInstance();
        let subscriptionsPackage: SubscriptionPackage[] = [];
        let offeringPackages = [];
        let reductions: any = {};
        let higherPriceByMonth: { packageType: PACKAGE_TYPE | undefined, price: number } = {
            packageType: undefined,
            price: -1
        };
        if (offerings !== null) {
            if (user.profile === "teacher") {
                if ((offerings.all !== undefined) && (offerings.all.LASV2 !== undefined)) {
                    offeringPackages = offerings.all.LASV2.availablePackages;
                }
            } else if (user.profile === "family") {
                if ((offerings.all !== undefined) && (offerings.all.LAHV2 !== undefined)) {
                    offeringPackages = offerings.all.LAHV2.availablePackages;
                }
            }
        }
        // TODO : REDISCTRIUER LES PACKAGES "PUCHASES" DANS DES PACKAGES "LILI"
        for (const anOfferingIndex in offeringPackages) {
            if (Object.prototype.hasOwnProperty.call(offeringPackages, anOfferingIndex)) {
                const aPackage = offeringPackages[anOfferingIndex];
                if (aPackage.packageType === "MONTHLY") {
                    if (aPackage.product.price > higherPriceByMonth.price) {
                        higherPriceByMonth = {
                            price: aPackage.product.price,
                            packageType: aPackage.packageType
                        }
                    }
                } else if (aPackage.packageType === "THREE_MONTH") {
                    const priceByMonth: number = aPackage.product.price / 3;
                    if (priceByMonth > higherPriceByMonth.price) {
                        higherPriceByMonth = {
                            price: priceByMonth,
                            packageType: aPackage.packageType
                        }
                    }
                }
                subscriptionsPackage.push(aPackage);
            }
        }
        for (const anOfferingIndex in offeringPackages) {
            if (Object.prototype.hasOwnProperty.call(offeringPackages, anOfferingIndex)) {
                const aPackage = offeringPackages[anOfferingIndex];
                if (numberOfMonthsByPackageType[aPackage.packageType] !== undefined) {
                    let reduction: number = 0;
                    let priceByMonth: number = aPackage.product.price / numberOfMonthsByPackageType[aPackage.packageType];
                    if (higherPriceByMonth.price > priceByMonth) {
                        reduction = (1 - (priceByMonth / higherPriceByMonth.price)) * 100;
                        reductions[aPackage.packageType] = Math.floor(reduction);
                    }
                }

            }
        }
        // On va maintenant comparer les packages avec le prix le plus élevé pour avoir la réduction de chacun
        return {
            package: subscriptionsPackage,
            reductions
        }
    } catch (error) {
    }
}

export const createCheckoutSession = async (data: { product: any, presubId: string }) => {
    const { product, presubId } = data;
    const user: User = User.getInstance();
    const host = window.location.origin;
    const successUrl = host + ScreenStripeCallback + "/success/" + presubId;
    const cancelUrl = host + ScreenStripeCallback + "/cancel/" + presubId;
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
        lineItems: [
            {
                price: stripeVersionProd === true ? product.stripe_price_id : product.stripe_price_id_test,
                quantity: 1,
            },
        ],
        clientReferenceId: user.uid,
        mode: product.productCategory === "SUBSCRIPTION" ? "subscription" : "payment",
        successUrl,
        cancelUrl,
        customerEmail: user.email,
    });
}

export const treatPurchaserInfo = (purchaserInfo: any) => {
    if (purchaserInfo !== undefined) {
        if (purchaserInfo.entitlements !== undefined) {
            if (purchaserInfo.entitlements.active !== undefined) {
                let activeSub = {};
                let isSub = false;
                for (const anEntitlementsKey in purchaserInfo.entitlements.active) {
                    if (Object.prototype.hasOwnProperty.call(purchaserInfo.entitlements.active, anEntitlementsKey)) {
                        const element = purchaserInfo.entitlements.active[anEntitlementsKey];
                        if (anEntitlementsKey === "LAS") {
                            const now = new Date().getTime();
                            if (element.expirationDateMillis !== null) {
                                if (element.expirationDateMillis > now) {
                                    activeSub = {
                                        creationDate: element.originalPurchaseDateMillis,
                                        date: element.originalPurchaseDateMillis,
                                        expires_date: element.expirationDateMillis,
                                        origin: element.store,
                                        product_id: element.productIdentifier,
                                        start_date: element.originalPurchaseDateMillis
                                    };
                                    isSub = true;
                                    break;
                                }
                            } else {
                                const originalPurchaseDateMillis = element.originalPurchaseDateMillis;
                                let numberOfMonthTheAdd = 3;
                                if (element.productIdentifier === "cool.lili.sub.laspremiumtwelve") {
                                    numberOfMonthTheAdd = 12;
                                }
                                const originalPurchaseDate = new Date(originalPurchaseDateMillis)
                                const expirationDate = new Date(originalPurchaseDate.setMonth(originalPurchaseDate.getMonth() + numberOfMonthTheAdd));
                                if (expirationDate.getTime() > now) {
                                    activeSub = {
                                        creationDate: element.originalPurchaseDateMillis,
                                        date: element.originalPurchaseDateMillis,
                                        expires_date: expirationDate.getTime(),
                                        origin: element.store,
                                        product_id: element.productIdentifier,
                                        start_date: element.originalPurchaseDateMillis
                                    };
                                    isSub = true;
                                    break;
                                }
                            }
                        } else if (anEntitlementsKey === "Premium") {
                            const now = new Date().getTime();
                            if (element.expirationDateMillis !== null) {
                                if (element.expirationDateMillis > now) {
                                    activeSub = {
                                        creationDate: element.originalPurchaseDateMillis,
                                        date: element.originalPurchaseDateMillis,
                                        expires_date: element.expirationDateMillis,
                                        origin: element.store,
                                        product_id: element.productIdentifier,
                                        start_date: element.originalPurchaseDateMillis
                                    };
                                    isSub = true;
                                    break;
                                }
                            }
                        }
                    }
                }
                if (isSub === true) {
                    return activeSub;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        } else {
            return null;
        }
    } else {
        return null;
    }
}

export const getPurchaserInfo = async () => {

}